import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, ModalTitle, ModalWindow } from '../../../components/Modal';
import { useCallback, useState } from 'react';
import { PublishCustomDomainV2 } from './PublishWebsiteComponents';
import { checkAppSumoFeature, PLANS } from 'zync-common/zyncCustomerPlans';
import { useWorkspacePlan } from '../../../hooks/useWorkspacePlan';
import { updateEpisodeSettingsApi } from '../../../helper/api';

export const PublishToCustomDomainModal = ({
  handleClose,
  highlights = [],
  workspace,
  series,
  localDispatch,
}) => {
  const { workspaceId } = workspace || {};
  const { plan, isAppSumo, activeSubscription, isTrial } = useWorkspacePlan();
  const isSoloPlan = plan === PLANS.solo.id;
  const isCustomDomainFeatureAvailable =
    (!isTrial && activeSubscription && isSoloPlan) ||
    (isAppSumo &&
      activeSubscription &&
      checkAppSumoFeature('customDomain', plan));
  const customDomainUrl = workspace?.settings?.domain;

  const firstHighlight = highlights[0];
  const {
    title,
    highlightId,
    HIGHLIGHT_SOCIAL_MEDIA_POSTS,
    sentences = [],
  } = firstHighlight || {};
  const { socialMediaPosts } = HIGHLIGHT_SOCIAL_MEDIA_POSTS || {};
  const { youtube } = socialMediaPosts || {};
  const { text: youtubeText } = youtube || {};

  const { meetingSeriesId } = series || {};
  const [settings, setSettings] = useState(series?.settings);
  const { highlights: highlightsSettings = {} } = settings || {};
  const highlightSettings = highlightsSettings?.[highlightId];
  const { socialMediaPosts: highlightSettingsSocialMediaPosts } =
    highlightSettings || {};
  const { customDomain: highlightCustomDomainSettings } =
    highlightSettingsSocialMediaPosts || {};
  const { text: settingsText, transcript: settingsTranscript } =
    highlightCustomDomainSettings || {};
  const highlightPublishedToCustomDomain =
    !!highlightsSettings[highlightId]?.publishToCustomDomain;

  const [summary, setSummary] = useState(settingsText || youtubeText);
  const [transcript, setTranscript] = useState(
    settingsTranscript || sentences.map((s) => s.text).join(' ')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const isPublishButtonDisabled =
    summary.trim().length === 0 || transcript.trim().length === 0 || isLoading;

  const updateHighlightsSettings = useCallback(
    async (value) => {
      setError(null);

      if (!transcript.trim().length) {
        setError('Transcript cannot be empty');
        return;
      }

      if (!summary.trim().length) {
        setError('Summary cannot be empty');
        return;
      }

      setIsLoading(true);
      const newHighlights = {
        ...highlightsSettings,
        [highlightId]: {
          ...highlightsSettings[highlightId],
          socialMediaPosts: {
            ...highlightsSettings[highlightId]?.socialMediaPosts,
            customDomain: {
              text: summary,
              transcript,
            },
          },
          publishToCustomDomain: value,
        },
      };
      const result = await updateEpisodeSettingsApi(meetingSeriesId, {
        highlights: newHighlights,
      });
      localDispatch &&
        localDispatch({ type: 'REFRESH_SERIES', series: result });
      setSettings(result.settings);
      setIsLoading(false);
    },
    [
      highlightId,
      highlightsSettings,
      localDispatch,
      meetingSeriesId,
      setIsLoading,
      summary,
      transcript,
    ]
  );

  if (highlights.length === 0) {
    return (
      <ModalWindow size={Modal.size.xl} onCancel={handleClose}>
        <ModalTitle>No content kit created</ModalTitle>
      </ModalWindow>
    );
  }

  return (
    <ModalWindow size={Modal.size.xl} overflow="overflow-visible">
      <div className="flex flex-col px-11 py-9 h-[70vh] overflow-auto purple-scrollbar">
        <button
          className="self-end text-2xl text-blue-gray absolute top-2 right-6"
          onClick={handleClose}
        >
          ×
        </button>
        <div className="flex justify-between w-full items-center">
          <span className="text-black text-xl font-semibold">{title}</span>
          <div className="flex gap-2">
            <PublishCustomDomainV2
              workspace={workspace}
              isPublishButtonDisabled={isPublishButtonDisabled}
              isLoading={isLoading}
              isCustomDomainFeatureAvailable={isCustomDomainFeatureAvailable}
              customDomainUrl={customDomainUrl}
              updateHighlightsSettings={updateHighlightsSettings}
              error={error}
              highlightPublishedToCustomDomain={
                highlightPublishedToCustomDomain
              }
            />
          </div>
        </div>
        <span className="mt-4 text-black text-sm font-semibold">
          Video Page
        </span>
        <div className="px-3 py-2 border border-[#EDEDED] bg-[#F7F7F7] rounded-[5px]">
          {highlightPublishedToCustomDomain ? (
            <a
              className="text-[#2D1FF9] decoration-[#2D1FF9] underline text-xs font-courier-prime font-bold"
              href={`https://${customDomainUrl}/${highlightId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${customDomainUrl}/${highlightId}`}
            </a>
          ) : (
            <Link
              className="text-[#2D1FF9] decoration-[#2D1FF9] underline text-xs font-courier-prime font-bold"
              to={`/${workspaceId}/videofeed/${highlightId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview Video Page
            </Link>
          )}
        </div>
        <div className="flex justify-between w-full">
          <span className="mt-2 text-black text-sm font-semibold">Summary</span>
        </div>
        <textarea
          className="resize-none w-full px-3 py-2 rounded-[5px] border border-[#C0C0C0] outline-none h-40 min-h-[160px] text-black"
          value={summary}
          onChange={(event) => {
            setSummary(event.target.value);
          }}
        ></textarea>
        <div className="flex justify-between w-full">
          <span className="mt-2 text-black text-sm font-semibold">
            Transcript
          </span>
        </div>
        <textarea
          className="resize-none w-full px-3 py-2 rounded-[5px] border border-[#C0C0C0] outline-none h-40 min-h-[160px] text-black"
          value={transcript}
          onChange={(event) => {
            setTranscript(event.target.value);
          }}
        ></textarea>
      </div>
    </ModalWindow>
  );
};
